import React, { useContext } from "react";
import { ReactComponent as LogoDay } from "../../assets/logo-day.svg";
import Logo from "../../assets/SMLogo.gif";
import { ReactComponent as LogoNight } from "../../assets/logo-night.svg";
import { ThemeContext } from "../../context/context";

const Navbar = () => {
  const { clicked } = useContext(ThemeContext);

  return (
    <>
      {clicked ? (
        <>
          <div className="hidden nav-night-logo-wrapper sm:flex sm:justify-start">
            <a href="/">
              <LogoNight className="hidden nav-night-logo sm:flex sm:h-14" />
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="hidden nav-day-logo-wrapper sm:flex sm:justify-start image-container-desk">
            <a href="/">
              <img
                src={Logo}
                alt="logo"
                className="hidden nav-night-logo sm:flex sm:h-14 smlogo"
              />
            </a>
          </div>
        </>
      )}

      <div className="nav-tabs hidden sm:flex sm:items-center nav-menu">
        <div className="music-tab ml-2 md:ml-6 lg:ml-30 nav-items">
          {/* <a
            href="https://dev-portal.syncmama.com/"
            
            rel="noreferrer"
            className="music-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg"
          > */}
          <a
            href={process.env.REACT_APP_BASE_URL}
            rel="noreferrer"
            className="music-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg nav-items"
          >
            MUSIC
          </a>
        </div>
        <div className="sound-tab ml-2 md:ml-10 lg:ml-8 nav-items">
          {/* <a
            href="https://dev-portal.syncmama.com/sound-design"
            
            rel="noreferrer"
            className="sound-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg"
          > */}
          <a
            href={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SOUND_DESIGN}
            rel="noreferrer"
            className="sound-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg nav-items"
          >
            SOUNDFX
          </a>
        </div>
        
        <div className="inspiration-tab ml-2 md:ml-10 lg:ml-16  nav-items">
          <a
            href={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_INSPIRATION}
            className="inspiration-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg"
          >
            INSPIRATION
          </a>
        </div>
        <div className="inspiration-tab ml-2 md:ml-10 lg:ml-16  nav-items">
          <a
            href={process.env.REACT_APP_BASE_URL + "catalogs"}
            className="inspiration-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg"
          >
            CATALOGS
          </a>
        </div>
        <div className="pricing-tab ml-2 md:ml-6 lg:ml-16 nav-items">
          <a
            href="#card-container"
            className="pricing-tab-link font-synthese400 dark:text-white text-black text-base sm:text-xs md:text-sm lg:text-lg"
          >
            PRICING
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
