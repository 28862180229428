import React from 'react';

import "./style.css";

export default function Cookies() {

  return (
    <div className='flex text-center flex-wrap'>
      <div className='self-center text-center w-full'>
        <h1 className='w-full description2'>Cookie Policy</h1>
        <div className='self-center text-left w-full main-content'>
    
    <h3>What are cookies?</h3>
    <p>All professional websites use cookies. They are tiny files that are downloaded to
            your computer. They are needed to provide all the services of a website and improve your user experience.
        </p>

    <p>We tell you now which cookies remain on your computer, which ones are deleted when
            you log out, and what information they give us about you.</p>

    <h3>The SyncMama cookies</h3>
    <p>When you create an account on syncmama.com, we use cookies for the management of the
            signup process and general administration. These cookies are deleted when you log out, except site
            preference cookies that help to remember your site preferences when you log in again. It is these cookies
            that remember how you use our site, its functionalities, and how you prefer to interact with each
            page.</p>

    <p>Preventing the storage of these essential cookies may downgrade your user experience
            and hamper the site&rsquo;s functionality and features.</p>

    <h3>Third-party cookies</h3>
    <p>We use Google Analytics to help us understand how you use the site and how we can
            improve your user experience. These cookies may track which pages you visit and the time you spend on them,
            so we can produce relevant and engaging content.</p>

    <p>We test regularly new features and make subtle changes to the functionalities and
            subscriber interface of the site. When testing new features, these cookies are used to ensure that you
            receive a consistent experience while we try to understand which new features our subscribers appreciate the
            most.</p>

    <p>But you should know that all this tracking is done anonymously; we know what the
            users do but we do not know who does it.</p>

    <p>For more information on Google Analytics cookies, see the official Google Analytics
            page here: https://analytics.google.com/analytics/academy/.</p>

    <h3>Additional information</h3>
    
    <p>Hopefully, all is now clear. But if you are still looking for more information, just
            contact us by e-mail: support@syncmama.com</p>


        </div>
      </div>
    </div>
  )
}