import React, { useContext, useRef, useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";
// import { ReactComponent as HandIcon } from '../../assets/hand1.svg';
import handIcon from "../../assets/hand1.png";
import { ReactComponent as FooterLogoDay } from "../../assets/footer-logo-day.svg";
import { ReactComponent as FooterLogoDayMobile } from "../../assets/footer-logo-day-mobile.svg";
import { ReactComponent as FooterLogoNight } from "../../assets/footer-logo-night.svg";
import { ReactComponent as FooterLogoNightMobile } from "../../assets/footer-logo-night-mobile.svg";

import { ReactComponent as BackArrowDay } from "../../assets/back-arrow.svg";
import { ReactComponent as BackArrowNight } from "../../assets/back-arrow-night.svg";
import { ReactComponent as ForwardArrowDay } from "../../assets/forward-arrow.svg";
import { ReactComponent as ForwardArrowNight } from "../../assets/forward-arrow-night.svg";
// import freeMusic from '../../assets/listen-for-free.png'
import { ThemeContext } from "../../context/context";
import SoloPlan from "../SoloPlan";
import SoloProPlan from "../SoloProPlan";
import CorporatePlan from "../CorporatePlan";
import FreePlan from "../FreePlan";
// import { CSSRulePlugin } from "gsap/CSSRulePlugin";
// import { TimelineMax } from 'gsap/all';
import "./style.css";
// import gsap from "gsap/gsap-core";

const PlansForYou = () => {
  const { clicked } = useContext(ThemeContext);
  //  gsap.registerPlugin(CSSRulePlugin);
  const myRef = useRef(null);
  const titleRef = useRef(null);
  const inputRef = useRef(null);
  const hand1Ref = useRef(null);

  useEffect(() => {
    // if (window.outerWidth >= 1367) {
    //   gsap.fromTo(
    //     titleRef.current,
    //     {
    //       x: -580,
    //       duration: 1,
    //     },
    //     {
    //       x: 0,
    //       scrollTrigger: {
    //         trigger: titleRef.current,
    //         start: "-=580 0%",
    //         end: "-=580 0%",
    //         markers: false,
    //       },
    //       duration: 1,
    //     }
    //   );
    // }
  }, []);

  useEffect(() => {
    // if (window.outerWidth >= 1367) {
    //   gsap.from(inputRef.current, {
    //     scaleX: 0,
    //     // minWidth: 50,
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: inputRef.current,
    //       start: "-=580 0%",
    //       end: "-=580 0%",
    //       markers: false,
    //     },
    //     duration: 1,
    //   });
    // }
  }, []);

  useEffect(() => {
    // if (window.outerWidth >= 1367) {
    //   gsap.fromTo(
    //     hand1Ref.current,
    //     {
    //       y: 100,
    //       opacity: 0,
    //       duration: 1,
    //     },
    //     {
    //       y: -20,
    //       zIndex: 10,
    //       opacity: 1,
    //       scrollTrigger: {
    //         trigger: hand1Ref.current,
    //         start: "-=450 0%",
    //         end: "-=450 0%",
    //         // markers: true
    //       },
    //       duration: 1,
    //     }
    //   );
    // }
  }, []);

  const handleBackArrow = () => {
    setTimeout(() => {
      myRef.current.scrollLeft -= window.outerWidth - window.outerWidth * 0.2;
    }, 25);
  };
  const handleForwardArrow = () => {
    setTimeout(() => {
      myRef.current.scrollLeft += window.outerWidth - window.outerWidth * 0.2;
    }, 25);
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <div
          className="card-container flex flex-col w-full bg-white dark:bg-black relative bg-light"
          id="card-container"
        >
          <div className="card-header-wrapper flex w-full flex-col px-4 sm:px-48 px-4  pt-8 sm:pt-16 sm:px-16 lg:px-14">
            <h2
              className="description2 font-campaign-choose-plan font-bold text-lg sm:text-3xl"
              style={{ color: "#E30185" }}
            >
              MAMA HAS A PLAN FOR YOU
            </h2>
          </div>
          <div className="hidden plan-caraousal-wrapper sm:w-full sm:flex sm:pt-8 sm:px-14 sm:overflow-y-hidden d-help w-100 sm-content-hidden tablet-content-scroller">
            {/* FreePlan------> */}
            <FreePlan />
            {/* <----- SOLO-PLAN -----> */}
            <SoloPlan />

            {/* SOLO - PRO - PLAN ------> */}
            <SoloProPlan />

            {/* CORPORATE - PLAN ------> */}
            <CorporatePlan />
          </div>
          <div
            ref={myRef}
            id="plan-caraousal-container"
            className="w-full flex relative overflow-x-scroll overflow-y-hidden sm:hidden"
          >
            <div
              id="plan-caraousal"
              className="plan-caraousal flex pt-6 px-4 sm:hidden "
              style={{ minWidth: `${window.innerWidth * 4 - 200}px` }}
            >
              {/* FREE-PLAN--------> */}
              <FreePlan />
              {/* <----- SOLO-PLAN -----> */}
              <SoloPlan />
              {/* SOLO - PRO - PLAN ------> */}
              <SoloProPlan />
              {/* CORPORATE - PLAN ------> */}
              <CorporatePlan />
            </div>
          </div>
          {/* <div className="flex w-full px-1 top-1/2 absolute justify-between sm:hidden"> */}
          <div className="btn1-container flex px-1 top-1/2 absolute  sm:hidden">
            {clicked ? (
              <button onClick={handleBackArrow}>
                <BackArrowNight />
              </button>
            ) : (
              <button onClick={handleBackArrow}>
                <BackArrowDay />
              </button>
            )}
          </div>
          <div
            className="btn2-container flex px-1 top-1/2 absolute sm:hidden"
            style={{ left: "91%" }}
          >
            {clicked ? (
              <button onClick={handleForwardArrow}>
                <ForwardArrowNight />
              </button>
            ) : (
              <button onClick={handleForwardArrow}>
                <ForwardArrowDay />
              </button>
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default PlansForYou;
