import React, { useRef, useEffect } from "react";
import rightTickIcon from "../../assets/correct-checkbox.png";
// import getStarted from '../../assets/get-started.png';
// import contactUs from '../../assets/contact-us.png';
import gsap from "gsap/gsap-core";
import "./style.css";
import Constants from "../../services/constants";

const SoloProPlan = () => {
  const soloProRef = useRef(null);

  useEffect(() => {
    // if ( window.outerWidth >= 1367 ) {
    //   gsap.fromTo(soloProRef.current, {
    //     y: 300,
    //     opacity: 0,
    //     // scrollTrigger: {
    //     //     trigger: titleRef.current,
    //     //     start:"-=260 30%",
    //     //     end:"-=250 30%",
    //     //     markers: true
    //     // },
    //     duration: 1
    //   },
    //   {
    //     y: 0,
    //     opacity: 1,
    //     scrollTrigger: {
    //         trigger: soloProRef.current,
    //         start:"-=750 0%",
    //         end:"-=750 0%",
    //         markers: false
    //     },
    //     duration: 2
    //   })
    // }
  }, []);

  return (
    <>
      <div className="width-24 tab-width plancolumn" ref={soloProRef}>
        <div
          className="w-full solo-plan-top planticket"
          style={{ background: "#d9de79"}}
        >
          <div className="solo-title-wrapper">
            <h1 className="solo-heading font-campaign-solo-plan font-bold text-black text-center pt-6 sm:pt-8 text-2xl sm:text-2xl">
              PRO
            </h1>
          </div>
          <div className="solo-desc-wrapper">
            <h1 className="solo-description font-synthese-solo-plan secondary-title">
              You are a freelancer creating commercial content for others
            </h1>
            <h3
              className="solo-description font-synthese-solo-plan secondary-title"
              style={{ color: "rgb(227, 1, 133)" }}
            >
              Same as SOLO, plus:
            </h3>
          </div>
          <div className="solo-bulletpoints-wrapper mt-3 pt-1 sm:pt-1">
            <div className="solo-bullet-points pt-4 sm:pt-0">
              <div className="point-1 flex mt-0 sm:mt-2 ml-8 items-start ">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Licenses for commercial, third-party use
                </p>
              </div>
              <div className="point-2 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Digital ads
                </p>
              </div>
              <div className="point-3 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Video creators
                </p>
              </div>
              <div className="point-4 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Documentary film-makers
                </p>
              </div>
              <div className="point-5 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Online journalists
                </p>
              </div>
              <div className="point-6 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Tutorials
                </p>
              </div>
              <div className="point-6 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  <b>Audio quality up to UltraHD 24bits on select tracks</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full solo-plan-bottom pt-3 sm:pt-4"
          style={{
            background: "#d9de79",
            height: "auto",
            padding: "10px",
            borderTop: "2px dashed #fff",
          }}
        >
          <div className="solo-plan-price-wrapper pb-6 sm:pb-3">
            <div className="price-container pt-4">
              <p className="price-container-yearly font-synthese-solo-plan font-normal text-center text-black text-xs sm:text-base">
                from
              </p>
              <h1 className="price-container-monthly font-synthese-solo-plan text-center text-black font-bold leading-tight text-base sm:text-xl">
                INR 1250 / month
              </h1>
            </div>
            <div className="solo-plan-link pt-4 sm:pt-4 flex items-center justify-center">
              <a
                href={
                 process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SIGN_UP + process.env.REACT_APP_SOLO_PRO
                }
                rel="noreferrer"
              >
                <div
                  className="solo-plan-btn font-synthese-solo-plan  w-44 sm:w-48 h-8 sm:h-10 text-sm sm:text-base rounded-full text-center flex justify-center items-center tablet-width-button"
                  style={{ background: "#FFFFFF" }}
                >
                  Get Started
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoloProPlan;
