import React from 'react';

export default function LicenseInformation() {

  return (
    <div className='flex text-center flex-wrap' style={{minHeight: '430px'}}>
      <div className='self-center text-center w-full'>
        <h1 className='w-full description2'>License Information</h1>
      </div>
    </div>
  )
}