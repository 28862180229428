import React from 'react';
import unavailable from "../../assets/unavailable.png";
import ContactUs from "../../components/ContactUs";
import "./style.css";

export default function CountryNotAvailable() {

  return (
    <div className='flex text-center flex-wrap'>
      <div className='self-center text-center w-full'>
        {/* <h1 className='w-full description2'>Not Available</h1> */}
        <div className='self-center text-left w-full'>
        <img
                  src={unavailable}
                  className="w-full"
                  alt="Sorry, SyncMama s Unavailable in your country"
                />
        </div>
        <div className='self-center text-left mt-5 w-full'>
        <ContactUs/>
        </div>
      </div>
    </div>
  )
}