import React, { useState, useContext } from "react";
import { ReactComponent as CloseDay } from "../../assets/close-day.svg";
import { ReactComponent as ProfileDay } from "../../assets/profile-day.svg";
import { ReactComponent as HamburgerMenuDay } from "../../assets/hamburger-day.svg";
import { ReactComponent as LogoDayMobile } from "../../assets/logo-day-mobile.svg";
import { ReactComponent as CloseNight } from "../../assets/close-night.svg";
import { ReactComponent as ProfileNight } from "../../assets/profile-night.svg";
import { ReactComponent as HamburgerMenuNight } from "../../assets/hamburger-night.svg";
import { ReactComponent as LogoNightMobile } from "../../assets/logo-night-mobile.svg";
//import { ReactComponent as DarkThemeSwitchDayMobile } from "../../assets/switch-day-mobile.svg";
//import { ReactComponent as DarkThemeSwitchNightMobile } from "../../assets/switch-night-mobile.svg";
import { ThemeContext } from "../../context/context";
import Logo from "../../assets/SMLogo.gif";
import "./style.css";

const MobileNav = () => {
  const { clicked, handleChange } = useContext(ThemeContext);

  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => {
    if (showMenu === false) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  };

  const handleMoveToPricing = (e) => {
    setShowMenu(false);
    e.preventDefault();
    var pricingNode = document.querySelector(".mobile-nav-bar #card-container");
    window.scrollTo(0, pricingNode.offsetTop);
  };

  const handleMoveToInspiration = (e) => {
    setShowMenu(false);
    e.preventDefault();
    var pricingNode = document.querySelector(
      ".mobile-nav-bar #inspiration-wrapper"
    );
    window.scrollTo(0, pricingNode.offsetTop);
  };

  return (
    <>
      <div className=" w-full flex justify-between h-14 z-10 items-center fixed bg-white dark:bg-black">
        {!clicked ? (
          <>
            <div>
              {showMenu === false ? (
                <HamburgerMenuDay
                  onClick={handleMenu}
                  className="flex ml-6 sm:hidden"
                />
              ) : (
                <CloseDay
                  onClick={handleMenu}
                  className="flex ml-6 sm:hidden"
                />
              )}
            </div>
            <div className="image-container">
              <a href="/">
                {/* <LogoDayMobile className="flex sm:hidden" /> */}
                <img src={Logo} alt="logo" className="flex sm:hidden mob-img" />
              </a>
            </div>
            <div>
              <ProfileDay className="flex w-16 h-6 sm:hidden" />
            </div>
          </>
        ) : (
          <>
            <div>
              {showMenu === false ? (
                <HamburgerMenuNight
                  onClick={handleMenu}
                  className="flex ml-6 sm:hidden"
                />
              ) : (
                <CloseNight
                  onClick={handleMenu}
                  className="flex ml-6 sm:hidden"
                />
              )}
            </div>
            <div>
              <a href="/">
                <LogoNightMobile className="flex sm:hidden" />
              </a>
            </div>
            <div>
              <ProfileNight className="flex w-16 h-6 sm:hidden" />
            </div>
          </>
        )}
      </div>

      {showMenu === true && (
        <div
          className="nav-tabs-mobile  flex w-4/5 pl-7 z-50 flex-col fixed min-h-screen sm:hidden"
          style={
            clicked
              ? { background: "#1D1D1D", top: "3.5rem" }
              : { background: "#F5F5F5", top: "3.5rem" }
          }
        >
          <div className="pt-8">
            {/* <a
              href="https://dev-portal.syncmama.com/"
              target="_blank"
              rel="noreferrer" */}
            <a
              href={process.env.REACT_APP_BASE_URL}
              target="_blank"
              rel="noreferrer"
              style={
                clicked
                  ? {
                    color: "#FFFFFF",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
                  : {
                    color: "#474747",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
              }
            >
              MUSIC
            </a>
          </div>
          <div className="pt-4">
            {/* <a
              href="https://dev-portal.syncmama.com/sound-design"
              target="_blank"
              rel="noreferrer" */}
            <a
              href={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SOUND_DESIGN}
              rel="noreferrer"
              style={
                clicked
                  ? {
                    color: "#FFFFFF",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
                  : {
                    color: "#474747",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
              }
            >
              SOUNDFX
            </a>
          </div>
          
          <div className="pt-4">
            <a
              href={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_INSPIRATION}
              // onClick={handleMoveToInspiration}
              style={
                clicked
                  ? {
                    color: "#FFFFFF",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
                  : {
                    color: "#474747",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
              }
            >
              INSPIRATION
            </a>
          </div>
          <div className="pt-4">
            <a
              href={process.env.REACT_APP_BASE_URL + "catalogs"}
              style={
                clicked
                  ? {
                    color: "#FFFFFF",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
                  : {
                    color: "#474747",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
              }
            >
              CATALOGS
            </a>
          </div>
          <div className="pt-4">
            <a
              href="#card-container"
              onClick={handleMoveToPricing}
              style={
                clicked
                  ? {
                    color: "#FFFFFF",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
                  : {
                    color: "#474747",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
              }
            >
              PRICING
            </a>
          </div>
          <div className="pt-4">
            {/* <a
              href="https://dev-portal.syncmama.com/signin"
              target="_blank"
              rel="noreferrer" */}
            {/* changes */}
            <a
              href={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SIGN_IN}
              rel="noreferrer"
              style={
                clicked
                  ? {
                    color: "#FFFFFF",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
                  : {
                    color: "#474747",
                    fontSize: "25px",
                    fontFamily: "synthese",
                  }
              }
            >
              SIGN IN
            </a>
          </div>
          {/* <div className="pt-14">
            {!clicked ? (
              <DarkThemeSwitchDayMobile onClick={handleChange} />
            ) : (
              <DarkThemeSwitchNightMobile onClick={handleChange} />
            )}
          </div> */}
        </div>
      )}
    </>
  );
};

export default MobileNav;
