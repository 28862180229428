import React, { useContext } from "react";
import { ReactComponent as FooterLogoDay } from "../../assets/footer-logo-day.svg";
import { ReactComponent as FooterLogoDayMobile } from "../../assets/footer-logo-day-mobile.svg";
import { ReactComponent as FooterLogoNight } from "../../assets/footer-logo-night.svg";
import { ReactComponent as FooterLogoNightMobile } from "../../assets/footer-logo-night-mobile.svg";
import { ThemeContext } from "../../context/context";
import playIcon from "../../assets/play-buttton.png";
import shareIcon from "../../assets/share-button.png";
import Logo from "../../assets/SMLogo.gif";
import "./style.css";

const Footer = () => {
  const { clicked } = useContext(ThemeContext);

  return (
    <>
      <div className="flex w-full flex-col">
        <div
          className="sound-design-container lg:px-36 bg-light footer-main-content-mobile"
          id="sound-design-container"
        >
          <div className="footer-container footer-container-spacing">
            <div className="flex w-full">
              <div className="footer-links w-4/5 flex justify-between sm:justify-start">
                <div className="flex flex-col w-full">
                  <a
                    href="/about"
                    className="link-heading text-xs sm:text-base font-synthese-sound-plan mb-2"
                    style={
                      !clicked ? { color: "#474747" } : { color: "#FFFFFF" }
                    }
                  >
                    About Us
                  </a>
                  <a
                    href="/terms"
                    className="link-heading text-xs sm:text-base font-synthese-sound-plan mb-2"
                    style={
                      !clicked ? { color: "#474747" } : { color: "#FFFFFF" }
                    }
                  >
                    Terms of Service
                  </a>
                  <a
                    href="/privacy"
                    className="link-heading text-xs sm:text-base font-synthese-sound-plan"
                    style={
                      !clicked ? { color: "#474747" } : { color: "#FFFFFF" }
                    }
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="flex flex-col w-full ">
                  <a
                    href="/cookies"
                    className="link-heading text-xs sm:text-base font-synthese-sound-plan mb-2"
                    style={
                      !clicked ? { color: "#474747" } : { color: "#FFFFFF" }
                    }
                  >
                    Cookie Policy
                  </a>
                  <a
                    href="/faqs"
                    className="link-heading text-xs sm:text-base font-synthese-sound-plan mb-2"
                    style={
                      !clicked ? { color: "#474747" } : { color: "#FFFFFF" }
                    }
                  >
                    FAQs
                  </a>
                  <a
                    href="/contact"
                    className="link-heading text-xs sm:text-base font-synthese-sound-plan"
                    style={
                      !clicked ? { color: "#474747" } : { color: "#FFFFFF" }
                    }
                  >
                    Contact Us{" "}
                  </a>
                </div>
              </div>

              <a
                href="/"
                className="w-full flex float-right sm:justify-end sm:items-start sm-sync-logo"
              >
                <div
                  className="logo hidden sm:w-1/2 sm:flex sm:justify-end sm:items-start"
                  style={{ width: "230px" }}
                >
                  {clicked ? (
                    <img src={Logo} alt="logo" className="footer-logo" />
                  ) : (
                    <img src={Logo} alt="logo" className="footer-logo" />
                  )}
                </div>
                <div className="logo w-1/3 flex justify-end items-start pb-14 sm:hidden">
                  {clicked ? (
                    <FooterLogoNightMobile />
                  ) : (
                    <FooterLogoDayMobile />
                  )}
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="px-4  pb-6  lg:px-36 bg-light">
          <div className="copyright-main pt-2 sm:pt-10 w-full flex border-t footer-text">
            <p className="copyright-details text-xs sm:text-base font-synthese-sound-plan common-content-text">
              &#169; {new Date().getFullYear()} Silk Road Communications. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
