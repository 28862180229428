import React, { useContext, useRef, useEffect } from "react";
import { ReactComponent as FooterLogoDay } from "../../assets/footer-logo-day.svg";
import { ReactComponent as FooterLogoDayMobile } from "../../assets/footer-logo-day-mobile.svg";
import { ReactComponent as FooterLogoNight } from "../../assets/footer-logo-night.svg";
import { ReactComponent as FooterLogoNightMobile } from "../../assets/footer-logo-night-mobile.svg";
import { ThemeContext } from "../../context/context";
import playIcon from "../../assets/play-buttton.png";
import shareIcon from "../../assets/share-button.png";
import gsap from "gsap/gsap-core";
import handIcon from "../../assets/hand1.png";
import sounddesign from "../../assets/sounddesign.png";
import voicegen from "../../assets/voicegen.png";
import "./style.css";

const SoundDesign = () => {
  const { clicked } = useContext(ThemeContext);
  const hand1Ref = useRef(null);
  const handRef = useRef(null);
  return (
    <React.Fragment>
      <div className="flex w-full flex-col">
        <div
          className="sound-design-container px-4  pt-8 sm:pt-16 sm:px-16 lg:px-36 bg-light sound-design-main-container"
          id="sound-design-container"
        >
          <div className="sound-design-heading sm:pt-10 pt-8">
            <h2
              className="description2 font-campaign-choose-plan font-bold text-lg sm:text-3xl my-4 text-font-mobile"
              style={{ color: "#E30185" }}
            >
              MUSIC FOR GLOBAL STORYTELLERS
            </h2>
            <div className="font-campaign-choose-plan font-semibold text-xl mb-10 text-font-mobile">
              <p className="text-xl subtitels-hight" style={{ color: "#000000" }}>
                ADD THAT PROFESSIONAL PUNCH TO YOUR VIDEOS
              </p>
            </div>
            <div className="sm:flex sm:flex-row-reverse items-center">
              <div
                className="sm:flex sm:pl-4 sm:-mt-30 px-8 sm:px-0"
                // style={{ width: "38%" }}
                ref={hand1Ref}
              >
                {/* <HandIcon /> */}
                <img
                  src={sounddesign}
                  alt="Hand Icon"
                  className="sound-img-tag"

                />
              </div>
              <div>
                <div
                  className="sound-description font-synthese-sound-plan text-sm sm:text-lg sm:w-4/5 pt-8 sm:pt-0"
                  style={!clicked ? { color: "#545454" } : { color: "#FFFFFF" }}
                >
                  <div
                    className="font-campaign-choose-plan font-semibold text-xl mobile-hide-content"
                  // style={{ fontSize: "130%", paddingBottom: "5%" }}
                  >
                    <h2
                      className="description2 font-campaign-choose-plan font-bold text-lg sm:text-3xl my-4"
                      style={{ color: "#E30185" }}
                    >
                      MUSIC FOR GLOBAL STORYTELLERS
                    </h2>
                    <p
                      className="text-xl text-font-tablet"
                      style={{ color: "#000000" }}
                    >
                      ADD THAT PROFESSIONAL PUNCH
                      <br />
                      TO YOUR VIDEOS
                    </p>
                  </div>
                  <p className="top-content-design">
                    <b className="text-black">600,000+</b> copyright-cleared tracks from award-winning composers, to give your videos that emotional connection to a global audience.
                    The power of Artificial Intelligence
                    (AI) to find the right track. New music every week. You can
                    even edit our tracks, use their stems, mix them with other
                    musical elements.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default SoundDesign;
