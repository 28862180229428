import React, { useContext, useRef, useEffect } from "react";
import { ReactComponent as FooterLogoDay } from "../../assets/footer-logo-day.svg";
import { ReactComponent as FooterLogoDayMobile } from "../../assets/footer-logo-day-mobile.svg";
import { ReactComponent as FooterLogoNight } from "../../assets/footer-logo-night.svg";
import { ReactComponent as FooterLogoNightMobile } from "../../assets/footer-logo-night-mobile.svg";
import { ThemeContext } from "../../context/context";
import playIcon from "../../assets/play-buttton.png";
import shareIcon from "../../assets/share-button.png";
import feature1 from "../../assets/feature1.png";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";

import "./style.css";

const FeatureAtGlance = () => {
  const { clicked } = useContext(ThemeContext);
  const hand1Ref = useRef(null);
  const hand1Ref1 = useRef(null);
  const hand1Ref2 = useRef(null);
  const hand1Ref3 = useRef(null);

  return (
    <>
      <div className="flex w-full flex-col">
        <div
          className="px-4 pt-12 sm:pt-16 px-4 pt-8 sm:pt-16 sm:px-16 lg:px-36 bg-light mt-10 sm-feature-glance sound-design-main-container"
          id="sound-design-container"
        >
          {/* Changes */}
          <div className="sound-design-heading">
            <h2
              className="description2 font-campaign-choose-plan font-bold text-lg sm:text-3xl"
              style={{ color: "#E30185" }}
            >
              FEATURES AT A GLANCE
            </h2>
            <div className="sm:flex sm:flex-wrap features justify-between">
              <div
                className="sound-description featurebox font-synthese-sound-plan text-sm sm:text-lg sm-width-100 "
                style={
                  !clicked
                    ? { color: "#545454"}
                    : { color: "#FFFFFF"}
                }
              >
                <div className="d-flex">
                  <div className="img-content">
                    <div className="set-img-height" ref={hand1Ref}>
                      {/* <Icon /> */}
                      <img
                        src={feature1}
                        className="hand-image "
                        alt="Hand Icon"
                      />
                    </div>
                    <div className="font-campaign-choose-plan font-semibold">
                      <p className="text-dark text-xl pl-8 text-font-tablet">
                      ALL THE MUSIC YOU NEED <br />
                      TO MAKE THAT PERFECT SOUNDTRACK
                      </p>
                    </div>
                  </div>
                </div>
                <p className="content-text">
                The most
                extensive and diverse music resource in the world for all your projects.
                All in 
                high-resolution (16 bits, 24 bits, Dolby Atmos). Stems available, ready-made
                edits: stings, underscores, 30 secs, 60 secs. Music made by pros composers 
                for pros content creators like you. 
                
                </p>
              </div>
              <div
                className="sound-description featurebox font-synthese-sound-plan text-sm sm:text-lg sm-width-100"
                style={
                  !clicked
                    ? { color: "#545454"}
                    : { color: "#FFFFFF"}
                }
              >
                <div className="d-flex">
                  <div className="img-content">
                    <div className="set-img-height  ref={hand1Ref1} ai-img-width">
                      {/* <Icon /> */}
                      <img
                        src={feature2}
                        className="hand-image "
                        alt="Hand Icon"
                      />
                    </div>
                    <div className="font-campaign-choose-plan font-semibold">
                      <p className="text-dark text-xl pl-8 text-font-tablet">
                        MAYA (AI) FINDS THE RIGHT TRACK
                          <br />
                        IN LESS THAN 10 SECONDS
                      </p>
                    </div>
                  </div>
                </div>
                <p className="content-text">
                  Finding the perfect music should be simple, right? It is. 
                  It only takes a few seconds. Upload a reference track or paste a YouTube link,
                  and you're done! Maya, powered by AI, recommends similar tracks from <b className="text-black">600,000+</b> compositions.
                  You can also conduct a refined manual search by utilising our numerous filters.
                </p>
              </div>
              <div
                className="sound-description featurebox font-synthese-sound-plan text-sm sm:text-lg sm-width-100"
                style={
                  !clicked
                    ? { color: "#545454"}
                    : { color: "#FFFFFF"}
                }
              >
                <div className="d-flex">
                  <div className="img-content">
                    <div className="set-img-height" ref={hand1Ref2}>
                      {/* <Icon /> */}
                      <img
                        src={feature3}
                        className="hand-image "
                        alt="Hand Icon"
                      />
                    </div>
                    <div className="font-campaign-choose-plan font-semibold">
                      <p className="text-dark text-xl pl-8 text-font-tablet">
                        OBTAIN LICENSES
                        <br />
                        IN YOUR NAME
                      </p>
                    </div>
                  </div>
                </div>
                <p className="content-text">
                  When a project is completed, our online tool enables you to
                  generate a license in your name. This license is valid
                  worldwide, in perpetuity, even when you are no more a
                  subscriber. It also ensures that your subscription fee is paid
                  to the artists whose music you have used.
                </p>
              </div>
              <div
                className="sound-description featurebox font-synthese-sound-plan text-sm sm:text-lg sm-width-100"
                style={
                  !clicked
                    ? { color: "#545454"}
                    : { color: "#FFFFFF"}
                }
              >
                <div className="d-flex">
                  <div className="img-content">
                    <div className="set-img-height" ref={hand1Ref3}>
                      {/* <Icon /> */}
                      <img
                        src={feature4}
                        className="hand-image "
                        alt="Hand Icon"
                      />
                    </div>
                    <div className="font-campaign-choose-plan font-semibold">
                      <p className="text-dark text-xl pl-8 text-font-tablet">
                        A FAIR MUSIC PARTNER
                        <br />
                        AN ETHICAL CHOICE
                      </p>
                    </div>
                  </div>
                </div>
                <p className="content-text">
                  Creators who enrich our lives and our business should get paid fairly for what they do.
                  Mama has implemented the best practices and technical standards recommended by CISAC and
                  DDEX to make sure that everyone in the value chain gets paid in a fair way.
                  Mama backs YourMusicYourFuture.com and CreditsDue.org 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureAtGlance;
