import React from "react";
import bannerMobile1 from "../../assets/billboard-mobile.jpg";
import bannerMobile2 from "../../assets/banner-mobile1-reduced.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./style.css";

const BannerMobile = () => {
  // var settings = {
  //   dots: true,
  // };

  return (
    <>
      {/* <div className="mobile-banner-wrapper flex flex-col absolute bg-black sm:hidden" style={{width: window.innerWidth}}> */}
      <Carousel
        showArrows={false}
        showThumbs={false}
        dynamicHeight={false}
        emulateTouch={true}
        showStatus={false}
      >
        <div>
          <img
            src={bannerMobile1}
            alt="SyncMama - All the resources to soundtrack your videos."
            className="mobile-banner"
          />
        </div>
        <div>
          <img
            src={bannerMobile2}
            className="relative"
            alt="SyncMama - The world's most complete music library by subscription."
          />
          {/* <div
            className="absolute z-10 top-0 w-full sm:hidden bg-red-00"
            style={{ height: "calc(100vh - 100px)" }}
           >
            <div className="flex flex-col pt-6 pl-4">
              <div className="flex flex-col mobile-banner-heading">
                <h1
                  className="mobile-banner-heading1 font-synthese-mob text-3xl font-extrabold leading-normal text-right pr-8"
                  style={{ background: "#FCE402" }}
                >
                  The World’s Largest
                </h1>
                <h1
                  className="mobile-banner-heading2 font-synthese-mob text-3xl font-extrabold leading-normal text-right px-8"
                  style={{
                    marginLeft: "18%",
                    width: "82%",
                    background: "#FCE402",
                  }}
                >
                  Music Library
                </h1>
              </div>
            </div>

            <div className="flex w-full">
              <div className="flex w-full">
                <h1
                  className="subscription-heading font-synthese-mob italic text-right px-10 w-full font-bold text-lg"
                  style={{ color: "#FFFFFF" }}
                >
                  by subscription
                </h1>
              </div>
            </div>
            <div className="pl-4 flex w-full absolute bottom-4">
              <div className=" flex w-full flex-col text-right bg-black pr-4 pt-2">
                <h1
                  className="description-wrapper font-synthese-mob text-sm leading-tight"
                  style={{ color: "#FFFFFF" }}
                >
                  255,000 tracks 38,000 SFX 1,200+ keywords
                </h1>
                <h1
                  className="description-wrapper font-synthese-mob text-sm leading-tight"
                  style={{ color: "#FFFFFF" }}
                >
                  AI audio search Unlimited licenses
                </h1>
                <h1
                  className="description-wrapper font-synthese-mob text-sm pb-4 leading-tight"
                  style={{ color: "#FFFFFF" }}
                >
                  Worldwide in perpetuity
                </h1>
              </div>
            </div>
          </div> */}
        </div>
      </Carousel>
      {/* </div>      */}
    </>
  );
};

export default BannerMobile;
