import React from "react";
import bannerImage from "../../assets/homepage-banner.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./style.css";

const Banner = () => {
  return (
    <div className="hidden banner-wrapper sm:flex sm:w-full sm:relative">
      <div
        className="hidden sm:flex sm:bg-black"
        style={{ width: "100%", minHeight: "500px" }}
      >
        <LazyLoadImage
          src={bannerImage}
          effect="blur"
          className="hidden sm:flex"
          alt="SyncMama - The world's most complete music library by subscription"
          width="100%"
          style={{ height: "100%" }}
        />
      </div>
      {/* <div
        className="banner-header hidden sm:w-1/2 sm:flex sm:flex-col sm:leading-none sm:items-center sm:absolute sm:z-10"
        style={{ height: "fit-content", top: "19%", left: "50%" }}
      >
        <h1
          className="banner-heading1 font-synthese700 font-bold text-right  sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl"
          style={{
            background: "#FCE402",
            paddingRight: "3%",
            paddingLeft: "3%",
            paddingBottom: "1.7%",
          }}
        >
          The World’s Largest
        </h1>
        <h1
          className="banner-heading2 ml-44 font-synthese700 font-bold text-right  sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl"
          style={{
            background: "#FCE402",
            paddingRight: "3.3%",
            paddingLeft: "3.3%",
            paddingBottom: "1.7%",
          }}
        >
          Music Library
        </h1>
      </div> */}
      {/* <div
        className="hidden sm:bg-black sm:flex sm:relative"
        style={{ width: "29%" }}
       >
        <div
          className="absolute subscription-wrapper"
          style={{ height: "fit-content", top: "40%", right: "14%" }}
        >
          <h1
            className="by-subscription text-right break-word w-full md:text-lg lg:text-xl xl:text-2xl"
            style={{ color: "#FFFFFF" }}
          >
            <span className="font-synthese700 font-bold italic">
              by subscription
            </span>
          </h1>
        </div>
        <div
          className="banner-end flex items-start justify-center flex-col w-full absolute"
          style={{ height: "fit-content", top: "60%", right: "14%" }}
        >
          <h1
            className="banner-title text-right break-word w-full font-synthese700 md:text-xl lg:text-2xl xl:text-3xl"
            style={{ color: "#FFFFFF" }}
          >
            255,000 tracks
          </h1>
          <h1
            className="banner-title text-right break-word w-full font-synthese700 md:text-xl lg:text-2xl xl:text-3xl"
            style={{ color: "#FFFFFF" }}
          >
            38,000 SFX
          </h1>
          <h1
            className="banner-title text-right break-word w-full font-synthese700 md:text-xl lg:text-2xl xl:text-3xl"
            style={{ color: "#FFFFFF" }}
          >
            1,200+ keywords
          </h1>
          <h1
            className="banner-title text-right break-word w-full font-synthese700 md:text-xl lg:text-2xl xl:text-3xl"
            style={{ color: "#FFFFFF" }}
          >
            AI audio search
          </h1>
          <h1
            className="banner-title text-right break-word w-full font-synthese700 md:text-xl lg:text-2xl xl:text-3xl"
            style={{ color: "#FFFFFF" }}
          >
            Unlimited licenses
          </h1>
          <h1
            className="banner-title text-right break-word w-full font-synthese700 md:text-xl lg:text-2xl xl:text-3xl"
            style={{ color: "#FFFFFF" }}
          >
            Worldwide in perpetuity
          </h1>
        </div>
      </div> */}
    </div>
  );
};

export default Banner;
