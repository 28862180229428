import React, { useEffect, useRef } from "react";
import gsap from "gsap/gsap-core";
import rightTickIcon from "../../assets/correct-checkbox.png";
import xIcon from "../../assets/x.png"

const FreePlan = () => {
  const freeplanRef = useRef(null);

  useEffect(() => { }, []);

  return (
    <>
      <div className="width-24 tab-width plancolumn" ref={freeplanRef}>
        <div
          className="w-full solo-plan-top planticket"
          style={{ background: "#faec64" }}
        >
          <div className="solo-title-wrapper">
            <h1 className="solo-heading font-campaign-solo-plan font-bold text-black text-center pt-6 sm:pt-8 text-2xl sm:text-2xl">
              FREE PLAN
            </h1>
          </div>
          <div className="solo-bulletpoints-wrapper mt-3 pt-1 sm:pt-1">
            <div className="solo-bullet-points pt-4 sm:pt-0">
              <div className="point-1 flex mt-0 sm:mt-2 ml-8 items-start ">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Full music search
                </p>
              </div>
              <div className="point-2 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Create project folders
                </p>
              </div>
              <div className="point-3 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={rightTickIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Share your projects (Coming soon)
                </p>
              </div>
              <div className="point-3 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={xIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Download
                </p>
              </div>
              <div className="point-3 flex mt-1 sm:mt-2 ml-8 items-start">
                <img
                  src={xIcon}
                  className="solo-point-img-desc pt-1 w-4"
                  alt="tick icon"
                />
                <p className="solo-point-desc font-synthese-solo-plan font-normal text-xs  leading-4 sm:leading-5 ml-4 mr-8">
                  Create a license
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full solo-plan-bottom pt-3 sm:pt-4"
          style={{
            background: "#faec64",
            height: "auto",
            padding: "10px",
            borderTop: "2px dashed #fff",
          }}
        >
          <div className="solo-plan-price-wrapper pb-6 sm:pb-3">
            <div className="price-container pt-4 visiblity-hidden">
              <p className="price-container-yearly font-synthese-solo-plan font-normal text-center text-black text-xs sm:text-base">
                from
              </p>
              <h1 className="price-container-monthly font-synthese-solo-plan text-center text-black font-bold leading-tight text-base sm:text-xl">
                INR 450 / month
              </h1>
            </div>
            <div className="solo-plan-link pt-4 sm:pt-4 flex items-center justify-center">
              <a
                href={process.env.REACT_APP_BASE_URL + process.env.REACT_APP_SIGN_UP + process.env.REACT_APP_FREE}
                rel="noreferrer"
              >
                <div
                  className="solo-plan-btn font-synthese-solo-plan  w-44 sm:w-48 h-8 sm:h-10 text-sm sm:text-base rounded-full text-center flex justify-center items-center tablet-width-button"
                  style={{ background: "#FFFFFF" }}
                >
                  Get Started
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreePlan;
