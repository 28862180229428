const Constants = Object.freeze({
  BASE_URL: "https://dev-portal.syncmama.com/",
  SIGN_UP: "signup/",
  SIGN_IN: "signin/",
  SOUND_DESIGN: "sound-design",
  VOICEGEN: "voicegen",
  CONTACT_US: "/contact",
  SOLO_PRO: "#solo_pro",
  SOLO: "#solo",
  FREE: "#free",
  INSPIRATION: "new-inspiration"
});
export default Constants;
