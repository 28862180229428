import React from 'react';

import "./style.css";

export default function TermsServices() {

  return (
    <div className='flex text-center flex-wrap'>
      <div className='self-center text-center w-full'>
        <h1 className='w-full description2 mt-10 text-2xl'>Terms Of Service</h1>
        <div className='self-center text-left w-full main-content'>

          <h3>DATED 1 DECEMBER 2021; LAST UPDATED ON: 1 NOVEMBER 2022.</h3>
          <p>Welcome to the SyncMama website accessible at <a className='underline' href='https://syncmama.com/ '>https://syncmama.com/ </a>(“Site”), owned by Silk
            Road Communications Private Limited (hereafter as ‘Silk Road’ or ‘we,’ ‘our’ or ‘us’), a private limited
            company located at A-1502 Thane One Corporate IT Park, DIL Complex, Kapurbawadi 400610 Thane
            (India) which provides an online music licensing service, by which we make available digital audio
            files (the “Content”) to be used in audio-visual productions and other productions (collectively,
            “Service”).</p>
            <p>These Terms of Service, including any modifications and revisions made to it from time to time
               (“Terms of Service”), together with the other documents referred to in these Terms of Service,
              including but not limited to the licensing policy, Privacy Policy, and the Cookie Policy (each of the
              preceding as available on the Site), are written in plain English and govern the use of this Site and
              our Service by any person (‘you, ‘your’ or ‘user’). Please read the Terms of Service carefully since it
              affects your legal rights, remedies, and obligations.</p>
           <p>By accessing or using the Site or Service or downloading any Content from or on the Site, you
              indicate that you have read, understood, and consent to be bound by, these Terms of Service,
              whether you have registered with the Site or otherwise. Use of the website by you is subject to
              compliance with these Terms of Service and therefore requires consent from you. Please do not use
              our Site or Service if you do not consent to our Terms of Service. Suppose you accept and agree to
              these Terms of Service on behalf of a company or other legal entity: then, in that case, you represent
              and warrant that you have the authority to bind that company or other legal entity to these Terms of
              Service and, in such event, ‘you,’ ‘your’ and ‘user’ shall refer and apply to that company or other
              legal entity.</p>

              <p>
              In addition to the Terms of Service, certain features of the Site (and your access to or use of certain
              aspects of the Service) may have different terms and conditions, standards, guidelines, or policies
              posted or require you to agree with and accept additional terms and conditions. Suppose there is a
              conflict between these Terms of Service and any terms and conditions posted for a specific area or
              feature of the Site or Service: then, in that case, the latter terms and conditions will take precedence
              concerning your use of or access to that area or feature of the Site or Service.
              </p>
          <h3>1. The SyncMama Service</h3>
          <p>
           The purpose of this Site is to enable you to use SyncMama’s Service.</p>
           <p>
           All use of the Content provided through the Service will be subject to a separate music license
           agreement between us and you that specifies your right to use and distribute the Content. We
           reserve the right to suspend or terminate the Service in the event of a violation of the Terms of
           Service. We may therefore deactivate your account at any time if you engage in any conduct or
           activities that we believe violate our Terms of Service as described here. The use of the Site and
           Service is not available to a user whose membership has been suspended or terminated for any
           reason whatsoever. 
          </p>     
          <p>
            We reserve the right to modify these Terms and Conditions at any time.</p>
          <h3>
            2. Creating an account</h3>
            <p>You represent and warrant that you are at least 18 years of age, or if you are under 18, your legal
               guardian shall consent to these Terms of Service for creating an account on your behalf.</p>
            <p>To use the Service, you must create a personal or a corporate account by following the instructions
               in the user registration process.</p>
               <p>You agree to provide us with accurate, complete information as requested in our registration
                  process. You also agree to update such information promptly to keep it up-to-date. The <b>Privacy
                  Policy </b> governs our use of the information found on the Site.</p>
            <p>You are not allowed to have more than one account and will not share your username and password
               with any other person for their use of our Site or Service. We reserve the right to suspend or
               terminate your account on the Site if you create more than one (1) account or if any information
               provided during the registration process or after that is found to be inaccurate, fraudulent, not
               current, incomplete, or otherwise in violation of these Terms of Service.</p>
            <p>You agree that you alone are responsible for all activity under your account on our Site. You are
               responsible for your username and password. You agree that you will not disclose your password to
               any third party and will take sole responsibility for any activities or actions under your Account,
               whether or not you have authorized such activities or actions. You must immediately notify us of any
               breach of security or unauthorized use of your account.</p>   
          <h3>
            3. Free account</h3>
          <p>
            Some of our Services are free. If you have created a free account, SyncMama reserves the right, in its sole discretion, to terminate your free account at any time with no prior written notice.</p>
          <h3>
            4. Payment</h3>
          <p>If you register for a Solo, Pro, or Corporate subscription, you agree to pay all fees for the Service for
             the selected period on a non-refundable basis. We reserve the right to change our prices and billing
             methods at anytime.</p>
          <p>You agree that Silk Road, through its Site, would raise a system-generated invoice to the user about
             the subscription and Services mentioned above or any payment done on the Site, as per applicable
             laws. Silk Road will raise an invoice for the above, which shall be exclusive of all applicable Taxes.</p>
          <p>A third-party payment method provider may impose additional terms and conditions, and the user is
             advised to go through all such terms and conditions. By making the payment toward the subscription
             and Services, the user agrees to be bound by such terms and conditions imposed by the third-party
             payment method provider.</p>   
          <h3>
            5. Term and Termination</h3>
          <p>You may stop using the Site or Service at any time. You do not need to inform us when you cease
             using our Site or Service.</p>
          <p>You acknowledge and agree that we may stop (permanently or temporarily) providing the Service (or
             any features within the Service) to you at our sole discretion. We will notify you and only charge you
             for any service you have received.</p> 
          <p>In case of termination, the clauses of these Terms of Service that reasonably should survive such
             termination will remain in effect.</p>  
          <h3>
            6. Intellectual Property</h3>
          <p>The Site, Service, and Content are protected by copyright, trademark, and other applicable
             intellectual property laws. You agree and acknowledge that Silk Road is the owner of the copyright,
             or authorized licensor, of all content used through the Site, including the sound recording, the
             underlying musical and literary work, the artwork, brand names, and the descriptive data.</p>
          <p>Under these Terms of Service, you have a limited, non-exclusive, non-transferable right to use our
             Site or Service to search, view, listen, organize, download, and obtain worldwide synchronization
             licenses for our Content as per the subscription plan opted by you and subject to adherence of these
             Terms of Service. You have no right to sublicense the license rights granted under these Terms of
             Service.</p>
          <p>We reserve all rights not explicitly granted herein. No licenses or rights are granted to you by
             implication or otherwise under any intellectual property rights owned or controlled by Silk Road or
             its licensors except for the licenses and rights expressly granted in these Terms of Service. Under no
             circumstance does the authorized use (license) of the Site’s Content give you ownership rights in and
             to that content or any part thereof. Therefore, you may not copy, adapt, modify, prepare derivative
             works on, distribute, license, sell, display, perform, transmit, broad, reuse, transfer, or otherwise
             exploit the Site, Service, or the Content, in whole or in part, for commercial, personal gain, or other
             purposes, except as expressly permitted in these Terms of Service and without our express
             permission (license). If you do so without permission (license), you may violate copyright and other
             applicable laws in addition to these Terms of Service.
              </p>
          <p>‘SyncMama’ logos displayed on the Site are a registered trademark of Silk Road and may not be
             altered, removed, copied, or otherwise exploited. Trademarks, service marks, logos, trade names,
             cover art, and any other proprietary designations of third parties used on or in connection with the
             Site, Service, and Content may be the property of their respective owners.</p>
          <h3>
            7. Usage restrictions</h3>
          <p>You cannot use our Content, alone or synched to any production, in a manner or context that is
             defamatory; illegal; inciteful of an unlawful act; immoral; pornographic; racist; hateful;
             discriminatory based on race, nationality, religion, ethnic identity, gender, gender identity or sexual
             orientation; that encourages violence or the use of violence; or in a manner or context that
             otherwise violates any human rights. Generally, you shall comply with applicable laws and
             regulations in your country or region.</p>
          <p>You shall not use manual or automated software, devices, scripts, robots, backdoors, or other means
             or processes to access, scrape, crawl, or ‘spider’ any web pages or other services contained in the
             Site, Service, or the Content. You shall not retrieve data or other content from our Site or Service to
             create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation,
             database, directory, or the like, whether by manual methods, through the use of bots, crawlers, or
             spiders, or otherwise. You shall not access, tamper with, or use non-public areas of the Site or
             Service, our computer systems, or the technical delivery systems of our providers.</p>  
          <p>You shall not attempt to probe, scan, or test the vulnerability of any of Silk Road’s systems or
             networks or breach any security or authentication measures.</p> 
          <p>You shall not avoid, bypass, remove, deactivate, impair, descramble, or circumvent any technological
             measure implemented by us or any other providers to protect the Site, Service, or Content.</p>
          <p>You shall not attempt to decipher, decompile, disassemble or reverse engineer any software used to
             provide the Site, Service, or Content.</p>
          <p>You shall not use the Site, Service, or Content for any purposes that are not expressly permitted by
             these Terms of Service or in a manner that falsely implies Silk Road’s endorsement, partnership, or
            otherwise misleads others about your affiliation with Silk Road.</p>
          <p>You shall not dilute, tarnish or otherwise harm the Silk Road brand in any way, including through
            unauthorized use of the Service or Content, registering and using Silk Road or ‘SyncMama’ or other
            derivative terms in domain names, trade names, trademarks, or other source identifiers, or
            registering and using domains names, trade names, trademarks or other source identifiers that
            closely imitate or are confusingly similar to Silk Road or ‘SyncMama’ domains, trademarks, taglines,
            promotional campaigns.</p>
          <p>You shall not copy, store or otherwise access or use any information on the Site, Service, or Content
             for purposes not expressly permitted by these Terms of Service.</p> 
          <p>You shall not interfere with or damage our Site or Services, including, without limitation, through the
             use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks,
             backdoors, packet or IP spoofing, forged routing or electronic mail address information or similar
             methods or technology.</p> 
          <p>You shall not register for more than one account on the Site or an account on behalf of an individual
            other than yourself, except in cases where the parent registers the account for its child or guardian
            for its ward.</p>  
          <p>You shall not impersonate any person or entity or falsify or otherwise misrepresent yourself or your
             affiliation with any person or entity.</p> 
          <p>Please also note that you are not allowed to upload, distribute or otherwise exploit the Content
             provided in the Service as an audio file (full-length or edited) on its own or with a background
             picture and just the name of the artist or the audio track.</p>     
          <p>You shall not advocate, encourage, or assist any third party in doing any of the preceding.</p>
          <h3>
            8. Third-Party Applications</h3>
          <p>The Service is integrated with third-party services to make the Service and Content available. We do
             not allow these third parties to collect any information about you, but we are not responsible or
             liable for the actions or performance of these third parties. We do not make any representations on
             behalf of such third parties.
          </p>
          <h3>
            9. Privacy and Personal Data</h3>
          <p>
          We may collect some of your data to provide the Service to you. We describe our collection, use,
          storage, and transfer of such personal data in our Privacy Policy. By accessing the Site or using our
          Service, you accept and consent to process your data as laid out in our Privacy Policy (as may be
          updated from time to time). We fully comply with applicable data protection and privacy laws,
          including the EU General Data Protection Regulation.</p>
          <h3>
            10. Service Level and Disclaimer</h3>
          <p> If you choose to use the Site, Application, Services, or Collective Content, you do so at your sole risk.
              The Site, Service, and Content are provided “as is” without warranty of any kind, either express or
              implied. We shall make reasonable efforts to keep the Service operational. However, we make no
              warranty that the Site, Service, or Content will meet your requirements or be available
              uninterrupted, securely, or error-free. Technical problems or maintenance may, from time to time,
              result in interruptions. We are not responsible for the unavailability or deficiencies of the Service
              caused by you. You acknowledge that programming errors are unavoidable in the software industry
              and that you use the Service at your own risk. We are not responsible to you for any lack of
              performance or the unavailability or failure of the website.</p>
          <p>We reserve the right at any time, and from time to time, to modify or discontinue, temporarily or
            permanently, functions and features of the Service with or without notice. We shall have no liability
            whatsoever to you in such an event.</p>
          <h3>
            11. LIMITATION OF LIABILITY &amp; INDEMNITY</h3>
          <p>Under no circumstances shall Silk Road be held responsible for damages suffered by the user in
             connection with the use of the Service, or have any liability of whatsoever nature, whether in
             contract or otherwise, for any losses whatsoever and howsoever caused from or in any manner
             connected with the Service.</p>
          <p>To the maximum extent permitted by law, neither we nor any other party involved in creating,
             producing, or delivering the Site, Service, or Content is not liable to you for any indirect, incidental,
             consequential, or punitive damages resulting from your use of the Site, Service, or Content, including
             but not limited to, loss of income, anticipated profits, sales, goodwill, or data, any interruption in
             Service, computer damage or system failure, any personal or bodily injury or emotional distress
             arising out of or in connection with these Terms of Service, or from the use of or inability to use the
             Site, Service or Content, from any communications, interactions or meetings with other users of the
             Site, Service0 or Content, or other persons with whom you may communicate or interact as a result of  
             your use of the Site, Service, or Content.</p>   
          <p>We will remain free from all claims, costs, damages, and expenses (including legal fees) incurred by
            you. You agree that, to the extent permissible by applicable law, your sole and exclusive remedy for
            any dissatisfaction with the Service is to stop using the Service.</p>   
          <p>You agree to release, defend, indemnify, and hold Silk Road and its affiliates and subsidiaries, and
            their officers, directors, employees, and agents, harmless from and against any claims, liabilities,
            damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees,
            arising out of or in any way connected with your access to or use of the Site, Services, or Content or 
            your violation of these Terms of Service.</p>  
          <h3>
            12. Assignment</h3>
          <p>
           You may not assign or transfer these Terms of Service to any party. Silk Road may assign or transfer
           these Terms of Service, at its sole discretion, without restriction. Subject to the terms and conditions
           stated herein, these Terms of Service will bind and inure to the benefit of Silk Road, its successors,
           and permitted assigns.
          </p>
          <h3>
            13. Partial Invalidity</h3>
          <p>
          Suppose, for any reason, a court of competent jurisdiction finds any provision of these Terms of
          Service invalid or unenforceable at any time or to any extent: that provision will be enforced to the
          maximum extent permissible, and the remainder of these Terms of Service shall not be affected.</p>
          <p>Each provision of these Terms and Conditions shall be valid and enforced to the fullest extent
             permitted by the law.</p>
          <h3>
            14. Force majeure</h3>
          <p>
          We shall not be liable to you for any delay or failure to perform our obligations hereunder if such
          delay or failure arises from any cause or causes beyond our reasonable control. Such causes shall
          include but are not limited to acts of God, floods, fires, loss of electricity or other utilities, labor
          strike, or delays by you in performing any requirements hereunder.</p>
          <h3>
            15. Applicable Law and Dispute Resolution</h3>
          <p>This Agreement shall be governed by and construed by the laws of India. Any dispute, controversy,
             or claim arising out of or relating to these Terms of Service or the breach, termination, or invalidity
             thereof shall be submitted to the exclusive jurisdiction of courts in Mumbai.</p> 
          <p>Users who access different versions of our Site applicable in other geographical locations do so on
             their initiative and at their own risk and are responsible for complying with local laws to the extent
             they are applicable.</p>    
          <p>We retain the right to initiate proceedings for damages or injunctions in any court having jurisdiction
             against illegal copying or distribution of the Content or the Service to the public.</p>
          <h3>
            16. Communications</h3>
          <p>By registering for a user account, you consent to receive legal and marketing communications from
             us. You may choose not to receive marketing communications. However, by your status as a user of
             our Service, you will still receive all legal and service updates or change notifications during your
             subscription period. We need all users to stay informed on how to interact with our Site and use it
             appropriately.
          </p>
          <h3>
            17. Support</h3>
          <p>You will find answers to the most frequently asked questions about the Service in the FAQ section of
            the Site. If you would like to receive further information about the Service or regarding the Terms of
            Service, you are welcome to contact us at support@syncmama.com
                </p>
          <h3>18. NO WAIVER</h3>
          <p>Any failure on our part to enforce any right or provision of these Terms of Service will not constitute
             a waiver of future enforcement of that right or provision. The waiver of any such right or provision
             will be effective only if in writing and signed by a duly authorized representative of Silk Road. Except
             as expressly outlined/ stated in these Terms of Service, the exercise by either party of any of its
             remedies under these Terms of Service will be without prejudice to its other remedies under these
             Terms of Service or otherwise.</p>
             
        </div>
      </div>
    </div>
  )
}