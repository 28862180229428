import React, { useContext, useRef, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";
import handIcon from "../../assets/hand1.png";
import { ReactComponent as BackArrowDay } from "../../assets/back-arrow.svg";
import { ReactComponent as BackArrowNight } from "../../assets/back-arrow-night.svg";
import { ReactComponent as ForwardArrowDay } from "../../assets/forward-arrow.svg";
import { ReactComponent as ForwardArrowNight } from "../../assets/forward-arrow-night.svg";
import { ThemeContext } from "../../context/context";
import SoloPlan from "../SoloPlan";
import SoloProPlan from "../SoloProPlan";
import CorporatePlan from "../CorporatePlan";
import "./style.css";
import gsap from "gsap/gsap-core";
import syncdiff from "../../assets/syncdiff.png";
import search from "../../assets/search.png";
import register from "../../assets/register.png";
import print from "../../assets/print.png";

const intialvalue = [
  {
    img: search,
    title: "Hand Icon",
    text: "SEARCH  & DOWNLOAD",
    content:
      "All the tracks that may fit your project. No limit. Create project folders. Share them with your clients",
  },
  {
    img: register,
    title: "Hand Icon",
    text: "REGISTER YOUR PROJECT",
    content:
      "Register every track that you use in your project. Know that your money will go to those composers",
  },
  {
    img: print,
    title: "Hand Icon",
    text: "PRINT THE LICENSE",
    content:
      "Print out the license to protect your project. You are now covered. Everywhere and forever.",
  },
];

function ChoosePlansnew() {
  const { clicked } = useContext(ThemeContext);
  const [feature, setFeature] = useState(intialvalue);
  // gsap.registerPlugin(CSSRulePlugin);
  const myRef = useRef(null);
  const titleRef = useRef(null);
  const inputRef = useRef(null);
  const hand1Ref = useRef(null);
  const hand1Ref3 = useRef(null);
  const hand1Ref2 = useRef(null);
  const hand1Ref1 = useRef(null);
  useEffect(() => {
    // if (window.outerWidth >= 1367) {
    //   gsap.fromTo(
    //     hand1Ref.current,
    //     {
    //       y: 100,
    //       opacity: 0,
    //       duration: 1,
    //     },
    //     {
    //       y: -20,
    //       zIndex: 10,
    //       opacity: 1,
    //       scrollTrigger: {
    //         trigger: hand1Ref.current,
    //         start: "-=450 0%",
    //         end: "-=450 0%",
    //         // markers: true
    //       },
    //       duration: 1,
    //     }
    //   );
    // }
  }, []);

  useEffect(() => {
    // if (window.outerWidth >= 1367) {
    //   gsap.from(inputRef.current, {
    //     scaleX: 0,
    //     // minWidth: 50,
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: inputRef.current,
    //       start: "-=580 0%",
    //       end: "-=580 0%",
    //       markers: false,
    //     },
    //     duration: 1,
    //   });
    // }
  }, []);

  // useEffect(() => {
  //   if ( window.outerWidth >= 1367 ) {

  //     gsap.from(inputRef.current, {
  //       // scaleX: 0,
  //       width: 50,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: inputRef.current,
  //         start:"-=580 0%",
  //         end:"-=580 0%",
  //         markers: false,
  //       },
  //       duration: 1
  //     });
  //   }
  // }, [])

  useEffect(() => {
    // if (window.outerWidth >= 1367) {
    //   gsap.fromTo(
    //     hand1Ref.current,
    //     {
    //       y: 100,
    //       opacity: 0,
    //       // scrollTrigger: {
    //       //     trigger: titleRef.current,
    //       //     start:"-=260 30%",
    //       //     end:"-=250 30%",
    //       //     markers: true
    //       // },
    //       duration: 1,
    //     },
    //     {
    //       y: -20,
    //       zIndex: 10,
    //       opacity: 1,
    //       scrollTrigger: {
    //         trigger: hand1Ref.current,
    //         start: "-=450 0%",
    //         end: "-=450 0%",
    //         // markers: true
    //       },
    //       duration: 1,
    //     }
    //   );
    // }
  }, []);

  // const handleMouseHover = () => {
  //   document.getElementById("parent-title").style.transform = "translate(0,0)";
  //   // document.getElementById("desk-search").style.width = "100%";
  //   // document.getElementById("desk-search").style.transform = "scale(1)";

  //   // const rule = CSSRulePlugin.getRule('desk-search::placeholder');

  //   // gsap.to(rule, {
  //   //   cssRule:{

  //   //   },
  //   //   duration: 1,
  //   //   delay: 1
  //   // });
  //   // document.getElementById("desk-search").style.pla
  //   // document.getElementById("parent-title").style.transitionDelay = "1s";
  //   // document.getElementById("parent-title").style.transitionTimingFunction = "ease-in";
  // }

  const handleBackArrow = () => {
    setTimeout(() => {
      myRef.current.scrollLeft -= window.outerWidth - window.outerWidth * 0.2;
    }, 25);
  };
  const handleForwardArrow = () => {
    setTimeout(() => {
      myRef.current.scrollLeft += window.outerWidth - window.outerWidth * 0.2;
    }, 25);
  };

  // const handleSearchClick = () => {
  //   window.location = "#card-container"
  // }

  // const handleMobileSearchClick = () => {
  //   let text = document.getElementById("mob-search");
  //   console.log('Text:', text);
  // }

  return (
    <div className="flex flex-col w-full px-4 pt-0 sm:pt-16 sm:px-16 lg:px-36 bg-light sound-design-main-container">
      <div>
        <h2
          className=" description2 font-campaign-choose-plan font-bold text-lg sm:text-3xl w-full"
          style={{ color: "#E30185" }}
        >
          MAMA SYNCS DIFFERENT
        </h2>
      </div>
      <div>
        <div className="d-help mt-5 d-help-chooseplan">
          {feature.map((data, index) => {
            return (
              <div className="d-help width-20 sm-width-100 sm-width-20">

                <div
                  style={{ color: "white" }}
                  className="hidden sm:flex justify-between listen-for-free1 mb-5"
                >
                  <div className="sm:flex px-8 sm:px-0 different-img-content">
                    <img src={data.img} alt="Hand Icon" />
                  </div>
                  <h1
                    className="about-header1-title font-campaign-choose-plan font-semibold w-1/4 text-lg"
                    style={{ color: "white" }}
                  >
                    {index + 1}
                  </h1>
                </div>
                <div className="hidden sm:w-full sm:block">
                  <h1 className="text-xl text-dark">{data.text}</h1>
                </div>
                <div className="w-full text-center sm:text-left flex sm:hidden sm-justify-start">
                  <div
                    style={{ color: "white" }}
                    className="sm:w-1/2 flex items-center justify-center listen-for-free1 mr-2 mb-5"
                  >
                    <div>
                      <img
                        src={data.img}
                        className="hand-image mobile-responsive-img"
                        alt="Hand Icon"
                        style={{ marginLeft: "-60%" }}
                      />
                    </div>
                    <h1
                      className="about-header1-title font-campaign-choose-plan font-semibold w-1/4 text-xl"
                      style={{ color: "white" }}
                    >
                      {index + 1}
                    </h1>
                  </div>
                  <h1
                    className="about-header1 font-campaign-choose-plan font-semibold text-base text-data"
                    style={{ color: "#000000" }}
                  >
                    {data.text}
                  </h1>
                </div>
                <div className="">
                  <p className="content-text">{data.content}</p>
                </div>
              </div>
            );
          })}
          {/*  */}
          <div className="hidden sm:flex width-20">
            {/* <HandIcon /> */}
            <img src={syncdiff} alt="Hand Icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChoosePlansnew;
